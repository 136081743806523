import React, {useCallback} from 'react'
import {MobileAppStatusUI} from '../../types/MobileAppStatusUI'
import {PresetColorType} from 'antd/es/_util/colors' // eslint-disable-line import/named
import {styles} from './StatusLabel.styles'
import {config} from '../../config/config'
import LinkTag from '../shared/LinkTag/LinkTag'
import {BuildState, MobileAppBuildInfo} from '../../types/MobileAppBuildInfo'
import {CheckCircleFilled, ExclamationCircleFilled, SyncOutlined} from '@ant-design/icons'

export type StatusLabelProps = {
  status?: MobileAppStatusUI,
  buildInfo?: MobileAppBuildInfo,
}

export const StatusLabel: React.FC<StatusLabelProps> = ({status, buildInfo}) => {
  const buildState = buildInfo?.state

  const getColor = useCallback((): PresetColorType => {
    if (buildState === BuildState.Failed) {
      return 'red'
    }
    switch (status) {
      case MobileAppStatusUI.RequestInReview:
      case MobileAppStatusUI.CodeEvaluation:
      case MobileAppStatusUI.AppStoreReview:
        return 'blue'
      case MobileAppStatusUI.PendingOwnerAction:
      case MobileAppStatusUI.UserTesting:
      case MobileAppStatusUI.DivisionalApproval:
      case MobileAppStatusUI.AppInfoNeeded:
        return 'volcano'
      default:
        return 'red'
    }
  }, [buildState, status])

  const getIcon = useCallback(() => {
    if (buildState === BuildState.Success) {
      return <CheckCircleFilled style={styles.successIcon} />
    } else if (buildState === BuildState.Failed) {
      return <ExclamationCircleFilled />
    } else if (buildState === BuildState.InProgress) {
      return <SyncOutlined spin />
    }
    return null
  }, [buildState])

  return status &&
  <LinkTag
    url={config.releaseProcessOverviewUrl}
    title={status}
    icon={getIcon()}
    tagColor={getColor()}
    tagStyle={styles.tag}
  />
}

StatusLabel.defaultProps = {
  status: undefined,
  buildInfo: undefined,
}
