import React from 'react'
import {Col, Collapse, Flex, Row, Space, Typography} from 'antd'
import {StatusLabel} from '../AppStatus/StatusLabel'
import {Link} from 'react-router-dom'
import {CaretRightOutlined} from '@ant-design/icons'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import {styles} from './AppRipSection.styles'
import {strings} from '../../localization/strings'
import StatusSettings, {AppStatusChangeCallbackParams} from '../AppStatus/StatusSettings'
import {useUserInfo} from '../../hooks/useUserInfo'
import {BuildState} from '../../types/MobileAppBuildInfo'

const {Title, Text} = Typography

export type AppRipSectionProps = {
    mobileApp: MobileAppDetail
    onAppStatusChange: AppStatusChangeCallbackParams
}

const AppRipSection: React.FC<AppRipSectionProps> = ({
  mobileApp, onAppStatusChange,
}) => {
  const {isAdmin} = useUserInfo()
  const {rip: texts} = strings.appDetail
  const {demo, statusUI, status: {buildInfo}, jiraIssue: {url: jiraLink}} = mobileApp
  const {state: buildState, errorMessage, url: buildUrl} = buildInfo || {}

  const getTestingDetails = () => (
    (
      <Row style={styles.testContainer}>
        <Col span={12} style={styles.demoContainer}>
          <Flex vertical gap="small">
            <Space style={styles.demoInstructions}>
              <Text strong>{texts.demoInstructions}</Text>
              <Text>{demo.instructions}</Text>
            </Space>
            {isAdmin && demo.username &&
            <Space>
              <Text strong>{texts.username}</Text>
              <Text>{demo.username}</Text>
            </Space>
            }
            {isAdmin && demo.password &&
            <Space>
              <Text strong>{texts.password}</Text>
              <Text>{demo.password}</Text>
            </Space>
            }
          </Flex>
        </Col>
        <Col span={12} style={styles.appTestersContainer}>
          <Flex vertical>
            <Text strong>{texts.appTesters}</Text>
            {mobileApp.testerEmails.map((email) => (
              <Text key={email}>{email}</Text>
            ))}
          </Flex>
        </Col>
      </Row>
    )
  )

  return (
    <Flex vertical gap="middle">
      <Title level={4}>{texts.title}</Title>
      <Flex vertical gap="middle">
        <Row>
          <Col span={1}>
            <Text strong>{texts.status}</Text>
          </Col>
          <Col>
            <Space>
              :
              <StatusLabel status={statusUI} buildInfo={buildInfo} />
              {buildState === BuildState.InProgress &&
              <Text type="secondary" italic>{texts.buildInProgress}</Text>
              }
              {buildState === BuildState.Failed && errorMessage &&
                <Text type="danger">{errorMessage}</Text>
              }
              {buildUrl &&
                <Link to={buildUrl} target="_blank">{texts.viewDetails}</Link>
              }
              {
                isAdmin &&
                buildState !== BuildState.InProgress &&
                buildState !== BuildState.Failed &&
                <StatusSettings
                  appId={mobileApp.id}
                  platform={mobileApp.platform}
                  currentStatus={mobileApp.status.name}
                  onAppStatusChange={onAppStatusChange} />
              }
            </Space>
          </Col>
        </Row>
        {jiraLink &&
        <Row>
          <Col span={1}>
            <Text strong>{texts.jiraLink}</Text>
          </Col>
          <Col>
            <Space>
              :<Link to={jiraLink}>{jiraLink}</Link>
            </Space>
          </Col>
        </Row>
        }
      </Flex>

      <Collapse
        bordered={false}
        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        items={[{
          key: '1',
          label: <Text strong>{texts.testingDetails}</Text>,
          children: getTestingDetails(),
        }]}
      />
    </Flex>
  )
}

export default AppRipSection
