import {objectDecoder, oneOfDecoders, stringDecoder, undefinedDecoder} from 'json-decoder'
import {Platform} from './Platform'
import {strings} from '../localization/strings'

export type MobileAppLinks = {
  appManager?: string // Apple AppStore Connect App (applicable to ABM and Public distributed applications) or Google Play App
  appPublicInstallation?: string // External Apple AppStore / Google Play link for public apps
  artifactoryRepository?: string
  ciCdPlatform?: string // Bitrise
  divisionalTracking?: string // e.g. AH WebUniverse
  internalAppStore?: string // Apps@Merck
  sourceCodeRepository?: string // Git
  vulnerabilityScan?: string // DataTheorem
}

export const mobileAppLinksDecoder = objectDecoder<MobileAppLinks>({
  appManager: oneOfDecoders(stringDecoder, undefinedDecoder),
  appPublicInstallation: oneOfDecoders(stringDecoder, undefinedDecoder),
  artifactoryRepository: oneOfDecoders(stringDecoder, undefinedDecoder),
  ciCdPlatform: oneOfDecoders(stringDecoder, undefinedDecoder),
  divisionalTracking: oneOfDecoders(stringDecoder, undefinedDecoder),
  internalAppStore: oneOfDecoders(stringDecoder, undefinedDecoder),
  sourceCodeRepository: oneOfDecoders(stringDecoder, undefinedDecoder),
  vulnerabilityScan: oneOfDecoders(stringDecoder, undefinedDecoder),
})

export const getAppLinksTitle = (key: keyof MobileAppLinks, platform: Platform) : string => {
  const {appLinks: linkTitle} = strings.appDetail

  if (key === 'appManager') {
    return platform === Platform.Ios ? linkTitle.appManagerIos : linkTitle.appManagerAndroid
  }

  const titles: { [K in keyof MobileAppLinks]?: string } = {
    appPublicInstallation: linkTitle.appInstallationLink,
    artifactoryRepository: linkTitle.artifactoryRepository,
    ciCdPlatform: linkTitle.bitrise,
    divisionalTracking: linkTitle.divisionalTracking,
    internalAppStore: linkTitle.appInstallationLink,
    sourceCodeRepository: linkTitle.sourceCode,
    vulnerabilityScan: linkTitle.vulnerabilityScan,
  }

  return titles[key] || ''
}

export const isAppInstallationLink = (key: keyof MobileAppLinks) : boolean =>
  key === 'appPublicInstallation' || key === 'internalAppStore'
