import {Styles} from '../types/Styles'

export const styles: Styles = {
  container: {
    margin: '24px',
    padding: '48px',
    background: 'white',
    borderRadius: 8,
  },
  buttonContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginTop: 30,
  },
  releaseButton: {
    borderRadius: 8,
    marginLeft: 10,
  },
  description: {
    marginTop: 20,
    whiteSpace: 'pre-wrap',
  },
}
