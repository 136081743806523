import React, {useCallback, useState} from 'react'
import {Button, Space, Typography, Flex, message} from 'antd'
import {strings} from '../../localization/strings'
import {AppLink} from '../../types/AppLink'
import AppInstallationLinkModal from './AppInstallationLinkModal'
import {
  MobileAppLinks,
  isAppInstallationLink,
  getAppLinksTitle,
} from '../../types/MobileAppLinks'
import {Platform} from '../../types/Platform'
import AppLinksEditor from './AppLinksEditor'
import useNetworkService from '../../services/NetworkService.hooks'
import {EditOutlined} from '@ant-design/icons'
import {useUserInfo} from '../../hooks/useUserInfo'
import {MobileAppStatus} from '../../types/MobileAppStatus'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {DistributionMethod} from '../../types/DistributionMethod'
import {objectEntries} from '../../helpers/ObjectHelper'

export type AppLinksSectionProps = {
  links: MobileAppLinks
  id: string
  platform: Platform
  distributionMethod: DistributionMethod
  status: MobileAppStatus
}

const {Title} = Typography

const AppLinksSection: React.FC<AppLinksSectionProps> = ({
  links: initialLinks,
  id,
  platform,
  distributionMethod,
  status,
}) => {
  const {appLinks: texts} = strings.appDetail
  const {isAdmin} = useUserInfo()

  const filterLinks = useCallback((links: MobileAppLinks) => {
    if (!links) return {}

    const filteredLinks = {...links}

    if (distributionMethod === DistributionMethod.Public) {
      delete filteredLinks.internalAppStore
    } else if (distributionMethod === DistributionMethod.ABM) {
      delete filteredLinks.appPublicInstallation
    }

    return filteredLinks
  }, [distributionMethod])

  const [links, setLinks] = useState<MobileAppLinks>(filterLinks(initialLinks))
  const [appInstallationLink, setAppInstallationLink] = useState<AppLink | undefined>(undefined)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const {updateMobileApp, isLoading} = useNetworkService()

  const handleOnAppInstallationLinkClick = useCallback((
    appLink: AppLink,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    setAppInstallationLink(appLink)
  }, [])

  const handleOnCloseAppInstallationLinkModal = useCallback(() => {
    setAppInstallationLink(undefined)
  }, [])

  const renderAppInstallationLinkButton = (appLink: AppLink) => (
    <Button
      type="primary"
      onClick={(event) => handleOnAppInstallationLinkClick(appLink, event)}
      style={{borderRadius: 5}}
    >
      {appLink.title}
    </Button>
  )

  const renderDefaultAppLinkButton = (appLink: AppLink) => (
    <Button
      type="primary"
      href={appLink.link}
      target="_blank"
      style={{borderRadius: 5}}
    >
      {appLink.title}
    </Button>
  )

  const renderAppLinksButtons = () => {
    const hasLinks = Object.values(links).some((link) => link)

    if (!hasLinks) {
      return <Typography.Text>{texts.noAppLinksFound}</Typography.Text>
    }

    return (
      <Flex wrap="wrap" gap={'small'}>
        {
          objectEntries(links).map(([key, link]) => {
            if (!link) {
              return null
            }
            const title = getAppLinksTitle(key, platform)
            return (
              <Space key={title}>
                {
                  isAppInstallationLink(key)
                    ? renderAppInstallationLinkButton({title, link})
                    : renderDefaultAppLinkButton({title, link})
                }
              </Space>
            )
          })
        }
      </Flex>
    )
  }

  const saveLinks = useCallback(async (updatedLinks: Partial<MobileAppLinks>) => {
    try {
      await updateMobileApp(id, platform, {links: updatedLinks})
      setLinks(updatedLinks)
      message.success(texts.appLinksUpdated)
      setIsEditing(false)
    } catch (error: any) {
      message.error(texts.appLinksUpdateFailed)
    }
  }, [id, platform, texts, updateMobileApp])

  return (
    <div>
      <Space direction="vertical">
        <Flex align="baseline">
          <Title level={4}> {texts.title} </Title>
          {!isEditing && isAdmin && status.name === MobileAppStatusName.Released &&
          <Button type="link" onClick={() => setIsEditing(true)}>
            <EditOutlined />
          </Button>}
        </Flex>
        {!isEditing && renderAppLinksButtons()}
      </Space>
      {
        appInstallationLink &&
        <AppInstallationLinkModal
          appLink={appInstallationLink}
          onCloseAppInstallationLinkModal={handleOnCloseAppInstallationLinkModal} />
      }

      {isEditing && (
        <AppLinksEditor
          links={links}
          onSave={saveLinks}
          onCancel={() => setIsEditing(false)}
          platform={platform}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default AppLinksSection
