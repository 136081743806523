import React from 'react'
import {Form, Button, Flex} from 'antd'
import AppInformationSection from './AppInformationSection'
import {strings} from '../../localization/strings'
import ComplianceSection from './ComplianceSection'
import TestingSection from './TestingSection'
import {styles} from './AppSubmissionForm.styles'
import PackagingSection from './PackagingSection'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import AppPublishingInformationSection from './AppPublishingInformationSection'
import {AppPublishingInformationHelper} from '../../helpers/AppPublishingInformationHelper'
import {useMaspNavigate} from '../../hooks/useMaspNavigate'

export type AppSubmissionFormProps = {
  isAppUpdate: boolean
  isInEditMode: boolean
  initialFormData: Partial<MobileAppDetail>
  previousAppVersion: string
  onSubmit: (data: Partial<MobileAppDetail>) => void
}

const AppSubmissionForm: React.FC<AppSubmissionFormProps> = ({
  isAppUpdate,
  isInEditMode,
  initialFormData,
  previousAppVersion,
  onSubmit,
}) => {
  const {goBack} = useMaspNavigate()

  const [form] = Form.useForm<Partial<MobileAppDetail>>()
  const {buttonTitle} = strings

  return (
    <div style={styles.formContainer}>
      <AppPublishingInformationSection
        appPublishingInformations={
          AppPublishingInformationHelper.appPublishingInformations
        }
      />
      <Form
        form={form}
        labelCol={{span: 7}}
        labelWrap
        wrapperCol={{span: 40}}
        initialValues={initialFormData}
        onFinish={onSubmit}
        layout="horizontal"
      >
        <div style={styles.formSection}>
          <AppInformationSection
            isAppUpdate={isAppUpdate}
            isInEditMode={isInEditMode}
            previousAppVersion={previousAppVersion}
          />
          <ComplianceSection isAppUpdate={isAppUpdate} />
          <TestingSection />
          <PackagingSection
            links={initialFormData.links ?? {}}
            isInEditMode={isInEditMode}
          />
        </div>

        <Flex gap="small" justify="end" style={styles.submitButtonContainer}>
          {isInEditMode &&
            <Button onClick={goBack}>
              {buttonTitle.cancelEditing}
            </Button>
          }
          <Button type="primary" htmlType="submit">
            {isInEditMode ? buttonTitle.update : buttonTitle.submit}
          </Button>
        </Flex>
      </Form>
    </div>
  )
}

export default AppSubmissionForm
