import {useRef, useEffect, useCallback} from 'react'

type IntervalCallback = () => void

export const usePollingInterval = (
  condition: boolean,
  callback: IntervalCallback,
  interval: number,
) => {
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null)

  const clearIntervalIfExists = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [])

  useEffect(() => {
    if (condition && !intervalId.current) {
      intervalId.current = setInterval(callback, interval)
    } else if (!condition) {
      clearIntervalIfExists()
    }

    return clearIntervalIfExists
  }, [condition, callback, interval, clearIntervalIfExists])
}
