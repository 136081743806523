import {Styles} from '../../types/Styles'

export const styles: Styles = {
  tag: {
    borderRadius: 6,
    marginRight: 0,
  },
  successIcon: {
    color: 'green',
  },
}
