import React, {memo} from 'react'
import {Flex, Spin, Typography} from 'antd'
import {CheckOutlined} from '@ant-design/icons'

const {Title, Text} = Typography

export type AppGeneralInformationItemProps = {
  title: string,
  value: string,
  isEditable?: boolean,
  onValueChange?: (value: string) => void
  isLoading?: boolean
}

const AppGeneralInformationItem: React.FC<AppGeneralInformationItemProps> = ({
  title,
  value,
  isEditable,
  onValueChange,
  isLoading,
}) => (
  <Flex vertical>
    <Title level={5}>{title}</Title>
    <Spin size="small" spinning={isLoading}>
      <Text
        editable={isEditable ? {onChange: onValueChange, enterIcon: <CheckOutlined />} : false}
      >
        {value}
      </Text>
    </Spin>
  </Flex>
)

export default memo(AppGeneralInformationItem)

AppGeneralInformationItem.defaultProps = {
  isEditable: false,
  onValueChange: undefined,
  isLoading: false,
}
