import React, {useRef, useCallback, memo} from 'react'
import {Button, Flex, Image, Typography} from 'antd'
import {LeftOutlined, RightOutlined} from '@ant-design/icons'
import './AppScreenshotsSection.css'
import {strings} from '../../localization/strings'

const DEFAULT_ICON = '/images/merck_teal_default_icon.png'
const {Title} = Typography

export type AppScreenshotsSectionProps = {
  screenshots: string[]
}

const AppScreenshotsSection: React.FC<AppScreenshotsSectionProps> = ({screenshots}) => {
  const carouselRef = useRef<HTMLDivElement>(null)

  const scrollLeft = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({left: -420, behavior: 'smooth'})
    }
  }, [])

  const scrollRight = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({left: 420, behavior: 'smooth'})
    }
  }, [])

  return (
    <Flex vertical>
      <Title level={4}> {strings.appDetail.appScreenshotsTitle} </Title>
      <div className="carousel-container">
        <Button
          className="carousel-arrow left"
          onClick={scrollLeft}
          shape="circle"
          icon={<LeftOutlined />}
          size="large" />
        <div className="carousel-wrapper" ref={carouselRef}>
          <Image.PreviewGroup>
            <div className="carousel-content">
              {screenshots.map((screenshot) => (
                <div key={screenshot} className="carousel-item">
                  <Image
                    src={screenshot}
                    width={400}
                    className="carousel-image"
                    fallback={DEFAULT_ICON} />
                </div>
              ))}
            </div>
          </Image.PreviewGroup>
        </div>
        <Button
          className="carousel-arrow right"
          onClick={scrollRight}
          shape="circle"
          icon={<RightOutlined />}
          size="large" />
      </div>
    </Flex>
  )
}

export default memo(AppScreenshotsSection)
