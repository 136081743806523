import React, {useLayoutEffect} from 'react'
import AppSubmissionForm from '../components/AppSubmission/AppSubmissionForm'
import {useLocation} from 'react-router'
import {Spinner} from '../components/Spinner'
import AppSubmissionResult from '../components/AppSubmission/AppSubmissionResult'
import {useAppSubmission} from './AppSubmission.hooks'

const AppSubmission: React.FC = () => {
  const {state} = useLocation()
  const {
    isLoading,
    loadingMessage,
    initialFormData,
    isAppUpdate,
    previousAppVersion,
    submissionResult,
    handleSubmit,
    handleUpdate,
    handleRetry,
  } = useAppSubmission(state)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoading) {
    return <Spinner tip={loadingMessage} />
  }

  if (submissionResult) {
    return (
      <AppSubmissionResult
        result={submissionResult}
        onRetry={handleRetry}
      />
    )
  }

  return (
    <AppSubmissionForm
      isAppUpdate={isAppUpdate}
      isInEditMode={state && state.isInEditMode}
      initialFormData={initialFormData}
      previousAppVersion={previousAppVersion}
      onSubmit={state && state.isInEditMode ? handleUpdate : handleSubmit}
    />
  )
}

export default AppSubmission
