import React, {useState} from 'react'
import {Col, Row, Typography, message} from 'antd'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import {strings} from '../../localization/strings'
import {DateHelper} from '../../helpers/DateHelper'
import AppGeneralInformationItem from './AppGeneralInformationItem'
import useNetworkService from '../../services/NetworkService.hooks'
import {useUserInfo} from '../../hooks/useUserInfo'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {regexPattern} from '../../types/RegexPattern'

const {Title} = Typography

export type AppGeneralInformationSectionProps = {
    mobileApp: MobileAppDetail
}

const AppGeneralInformationSection: React.FC<AppGeneralInformationSectionProps> = ({mobileApp}) => {
  const {
    id,
    platform,
    division,
    appIdentifier,
    cmdbId,
    publisher,
    distributionMethod,
    lastReleaseDate,
    contactEmail,
    assessments,
    status,
  } = mobileApp

  const {updateMobileApp, isLoading} = useNetworkService()
  const {isAdmin} = useUserInfo()

  const [currentContactEmail, setCurrentContactEmail] = useState(contactEmail)
  const {appGeneralInformation: texts} = strings.appDetail

  return (
    <>
      <Title level={4}> {texts.title} </Title>
      <Row>
        <Col span={7}>
          <AppGeneralInformationItem title={texts.appIdentifier} value={appIdentifier} />
          <AppGeneralInformationItem title={texts.division} value={division} />
          <AppGeneralInformationItem title={texts.appDistribution} value={distributionMethod} />
          <AppGeneralInformationItem title={texts.publisher} value={publisher} />
          <AppGeneralInformationItem
            title={texts.contact}
            value={currentContactEmail}
            isEditable={isAdmin && status.name === MobileAppStatusName.Released}
            onValueChange={
              async (value) => {
                if (value === currentContactEmail) {
                  return
                }
                if (!regexPattern.email.test(value) || !regexPattern.companyEmail.test(value)) {
                  message.error(strings.submission.validation.invalidCompanyEmail)
                  return
                }
                try {
                  await updateMobileApp(id, platform, {contactEmail: value})
                  setCurrentContactEmail(value)
                  message.success(texts.contactEmailUpdated)
                } catch (error: any) {
                  message.error(texts.contactEmailUpdateFailed)
                }
              }
            }
            isLoading={isLoading}
          />
        </Col>
        <Col span={7}>
          <AppGeneralInformationItem title={texts.cmdbId} value={cmdbId} />
          <AppGeneralInformationItem title={texts.riskId} value={assessments.riskId} />
          <AppGeneralInformationItem title={texts.privacyId} value={assessments.privacyId} />
          {lastReleaseDate &&
          <AppGeneralInformationItem
            title={texts.lastReleaseDate}
            value={DateHelper.getFormattedDate(lastReleaseDate)} />
          }
        </Col>
      </Row>
    </>
  )
}

export default AppGeneralInformationSection
