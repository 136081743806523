import React from 'react'
import './Home.css'
import {Flex, Input, Space} from 'antd'
import AppsSection from '../components/AppsSection/AppsSection'
import {Spinner} from '../components/Spinner'
import {strings} from '../localization/strings'
import {SearchOutlined} from '@ant-design/icons'
import {Section} from '../types/Section'
import {useUserInfo} from '../hooks/useUserInfo'
import MaspDataDownloader from '../components/DataExport/MaspDataDownloader'
import {useHome} from './Home.hooks'

const App: React.FC = () => {
  const {
    isLoading,
    filteredAndroidApps,
    filteredIosApps,
    filteredRips,
    handleAppStatusChange,
    rips,
    searchQuery,
    setSearchQuery,
  } = useHome()
  const {isAdmin} = useUserInfo()

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase())
  }

  if (isLoading && rips.length === 0) {
    return <Spinner tip={strings.loadingMessage.fetchingData} />
  }

  return (
    <>
      <Space direction="vertical" size="large" className="layout">
        <Flex align="center">
          <Input
            addonBefore={<SearchOutlined />}
            allowClear
            size="large"
            onChange={handleSearch}
            placeholder={strings.search.title}
            style={{
              margin: '20px 0px 0px 20px',
              paddingRight: '40px',
            }}
          />
          {isAdmin && <MaspDataDownloader />}
        </Flex>

        {rips.length > 0 && (
          <section className="appContentCard">
            <AppsSection
              section={Section.Rip}
              apps={filteredRips}
              searchQuery={searchQuery}
              onAppStatusChange={handleAppStatusChange}
            />
          </section>
        )}

        <section className="appContentCard">
          <AppsSection
            section={Section.Ios}
            apps={filteredIosApps}
            searchQuery={searchQuery}
          />
        </section>
        <section className="appContentCard">
          <AppsSection
            section={Section.Android}
            apps={filteredAndroidApps}
            searchQuery={searchQuery}
          />
        </section>
      </Space>
    </>
  )
}

export default App
