import {useNavigate} from 'react-router'
import {Pages} from '../components/Navigation'
import {Platform} from '../types/Platform'
import {useCallback} from 'react'

export type MaspNavigateResult = {
  goBack: () => void
  navigateHome: () => void
  navigateDetail: (id: string, platform: Platform) => void
  navigateSubmission: (id?: string, platform?: Platform, isInEditMode?: boolean) => void
}

export const useMaspNavigate = (): MaspNavigateResult => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const navigateHome = useCallback(() => navigate(Pages.home), [navigate])

  const navigateDetail = useCallback(
    (id: string, platform: Platform) => navigate(`${platform}/${id}`),
    [navigate],
  )

  const navigateSubmission = useCallback((
    id?: string,
    platform?: Platform,
    isInEditMode?: boolean,
  ) => {
    if (!id || !platform) {
      navigate(Pages.submission)
    }

    navigate(Pages.submission, {state: {id, platform, isInEditMode}})
  }, [navigate])

  return {goBack, navigateHome, navigateDetail, navigateSubmission}
}
