import React, {useState} from 'react'
import {Form, Radio, Input, Card, Typography, Flex} from 'antd'
import BinaryUploader from './BinaryUploader'
import AppSubmissionFormHeader from './AppSubmissionFormHeader'
import {strings} from '../../localization/strings'
import {useWatch} from 'antd/es/form/Form'
import {Platform} from '../../types/Platform'
import {DistributionMethod, getAppIdentifierPrefix} from '../../types/DistributionMethod'
import {fieldName} from './AppSubmissionFieldNames'
import {PackagingType} from '../../types/PackagingType'
import {MobileAppLinks} from '../../types/MobileAppLinks'

const {Text, Link} = Typography

export type PackagingSectionProps = {
  links: MobileAppLinks
  isInEditMode: boolean
}

const PackagingSection: React.FC<PackagingSectionProps> = ({
  links,
  isInEditMode,
}) => {
  const form = Form.useFormInstance()

  const {Item} = Form
  const {submission: texts} = strings
  const {validation: validationMessage, placeholder} = texts
  const packagingOptions = [
    {label: PackagingType.SourceCode, value: PackagingType.SourceCode},
    {label: PackagingType.Binary, value: PackagingType.Binary, disabled: isInEditMode},
  ]

  const [isBinaryUploaded, setIsBinaryUploaded] = useState<boolean>(false)
  const [packagingType, setPackagingType] = useState<PackagingType>(
    links.artifactoryRepository
      ? PackagingType.Binary
      : PackagingType.SourceCode,
  )
  form.setFieldsValue({[fieldName.packagingType]: packagingType})

  const platform = useWatch<Platform>(fieldName.platform, form)
  const distributionMethod = useWatch<DistributionMethod>(fieldName.appAvailability, form)
  const appIdentifier = useWatch<string>(fieldName.appIdentifier, form)
  const version = useWatch<string>(fieldName.version, form)

  const fieldsMissing = !platform || !appIdentifier || !version || !distributionMethod

  const onCompletion = (success: boolean, uploadUrl?: string) => {
    setIsBinaryUploaded(success)
    if (success && uploadUrl) {
      form.setFieldValue([fieldName.links, fieldName.artifactoryRepository], uploadUrl)
    }
  }

  const fieldsValidator = (_rule: any, value: any) => {
    if (value === PackagingType.Binary && fieldsMissing) {
      return Promise.reject(validationMessage.missingFieldForUpload)
    }
    return Promise.resolve()
  }

  const binaryUploadValidator = (_rule: any, value: any) => {
    if (value === PackagingType.Binary && !isBinaryUploaded && !fieldsMissing) {
      return Promise.reject(validationMessage.binaryMissing)
    }
    return Promise.resolve()
  }

  const renderUploadedBinary = () => (
    <Card style={{borderRadius: '15px'}}>
      <Flex vertical gap={'small'}>
        <Text strong>{texts.uploadedBinary}</Text>
        <Link href={links.artifactoryRepository} target="_blank">
          {links.artifactoryRepository}
        </Link>
        <Text type="secondary">
          {texts.binaryUpdateNote}
        </Text>
      </Flex>
    </Card>
  )

  const renderPackagingOptions = () => (
    <>
      <Item
        name={fieldName.packagingType}
        rules={[
          {required: true, message: validationMessage.packagingTypeRequired},
          {validator: fieldsValidator},
          {validator: binaryUploadValidator, validateTrigger: 'onBlur'},
        ]}>
        <Radio.Group
          options={packagingOptions}
          value={packagingType}
          onChange={(e) => setPackagingType(e.target.value)}
        />
      </Item>

      {packagingType === PackagingType.SourceCode && (
        <Item
          name={[fieldName.links, fieldName.sourceCodeRepository]}
          rules={[
            {required: true, message: validationMessage.sourceCodeUrlRequired},
            {type: 'url', message: validationMessage.invalidUrl},
          ]}
        >
          <Input placeholder={placeholder.sourceCodeUrl} />
        </Item>
      )}

      {packagingType === PackagingType.Binary && !fieldsMissing && (
        <Item
          name={[fieldName.links, fieldName.artifactoryRepository]}
        >
          <BinaryUploader
            platform={platform}
            appId={getAppIdentifierPrefix(distributionMethod) + appIdentifier}
            appVersion={version}
            onCompletion={onCompletion}
          />
        </Item>
      )}
    </>
  )

  return (
    <>
      <AppSubmissionFormHeader title={texts.packaging} />
      {
        isInEditMode && links.artifactoryRepository
          ? renderUploadedBinary()
          : renderPackagingOptions()
      }
    </>
  )
}

export default PackagingSection
