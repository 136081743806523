import {
  exactDecoder,
  objectDecoder,
  oneOfDecoders,
  stringDecoder,
  undefinedDecoder,
} from 'json-decoder'

export enum BuildState {
  Success = 'success',
  Failed = 'failed',
  InProgress = 'inProgress'
}

export const buildStateDecoder = oneOfDecoders(
  exactDecoder(BuildState.Success),
  exactDecoder(BuildState.Failed),
  exactDecoder(BuildState.InProgress),
)

export type MobileAppBuildInfo = {
  state: BuildState
  url: string
  errorMessage?: string
}

export const mobileAppBuildInfoDecoder = objectDecoder<MobileAppBuildInfo>({
  state: buildStateDecoder,
  url: stringDecoder,
  errorMessage: oneOfDecoders(stringDecoder, undefinedDecoder),
})
