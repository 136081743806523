/* eslint-disable max-len */
import {config} from '../config/config'

export const strings = {
  app: {
    loading: 'Loading...',
    loadingFailed: 'Unable to load Mobile App Service Portal',
    iOsAppsNotFound: `No iOS applications found.`,
    androidAppsNotFound: `No Android applications found.`,
  },
  error: {
    title: 'Error',
    detail: 'Error detail:',
    unknown: `Please refresh the page and try again. 
    If the problem persists, contact us at ${config.contactEmail}.`,
  },
  msal: {
    error: 'Authentication failed',
  },
  loadingMessage: {
    authentication: 'Authentication in progress...',
    fetchingData: 'Fetching data in progress...',
    submittingForm: 'Submitting the form...',
  },
  appsSection: {
    releasesInProgress: 'Releases In Progress',
    iOSApplications: 'iOS Applications',
    androidApplications: 'Android Applications',
  },
  appDetail: {
    title: 'App Information',
    notFound: 'App Information not found. Please try again later.',
    appDescriptionTitle: 'About the App',
    appScreenshotsTitle: 'Visual Previews',
    releaseInProgress: 'Release in progress',
    released: 'Released',
    cancelled: 'Cancelled',
    archived: 'Archived',
    retired: 'Retired',
    cancelSubmissionAlertTitle: 'Are you sure you want to cancel the submission?',
    cancelSubmissionAlertContent: 'This action cannot be undone.',
    submissionCancelled: 'App submission has been cancelled',
    submissionCancelledFailed: 'Failed to cancel app submission',
    editingDisabledBuildProgress: 'Editing is disabled while the build is in progress',
    appGeneralInformation: {
      title: 'App Specifications',
      appIdentifier: 'App Identifier',
      division: 'Division',
      cmdbId: 'CMDB ID',
      publisher: 'App Publisher',
      appDistribution: 'App Distribution',
      lastReleaseDate: 'Last Release Date',
      contact: 'Contact',
      riskId: 'Risk ID',
      privacyId: 'Privacy ID',
      contactEmailUpdated: 'Contact email updated successfully',
      contactEmailUpdateFailed: 'Failed to update contact email',
    },
    appLinks: {
      title: 'App Links',
      appManagerIos: 'Apple App Store Connect',
      appManagerAndroid: 'Google Play Console',
      appInstallationLink: 'App Installation Link',
      artifactoryRepository: 'Artifactory Repository',
      bitrise: 'Bitrise',
      divisionalTracking: 'Divisional Tracking',
      sourceCode: 'Source Code',
      vulnerabilityScan: 'Vulnerability Scan Report',
      scanQrCode: 'Scan the QR code to install the app',
      appLinksUpdated: 'App links updated successfully',
      appLinksUpdateFailed: 'Failed to update app links',
      noAppLinksFound: 'No app links found for this app',
    },
    rip: {
      title: 'Release Information',
      status: 'Status',
      jiraLink: 'Jira Link',
      testingDetails: 'Testing Details',
      demoInstructions: 'Demo Instructions',
      username: 'Username:',
      password: 'Password:',
      appTesters: 'App Testers',
      buildInProgress: 'build in progress...',
      viewDetails: '(view details)',
    },
  },
  buttonTitle: {
    ok: 'Ok',
    initialRelease: `Request Initial Release`,
    newRelease: 'Request a new release',
    remove: 'Remove',
    upload: 'Upload',
    submit: 'Submit',
    update: 'Update',
    cancelEditing: 'Cancel Editing',
    exportData: 'Export Data',
    jira: 'Jira',
    portfolio: 'Portfolio',
    statusChanges: 'Status Changes',
    editSubmission: 'Edit Submission',
    cancelSubmission: 'Cancel Submission',
    saveLinks: 'Save Links',
    cancel: 'Cancel',
  },
  search: {
    title: 'Enter Application Name',
    releaseInProgressNotFound: 'No release in progress found for the search query: ',
    iosAppNotFound: 'No iOS application found for the search query: ',
    androidAppNotFound: 'No Android application found for the search query: ',
  },
  fileUpload: {
    draggerText: 'Drag a file here or click to browse',
    draggerHint: 'Supported file types: ',
    selectedFile: 'Selected file:',
  },
  submission: {
    formDescriptionTitle: 'Please select correct version of release request process for your application:',
    customMobileAppDescription: 'Custom mobile apps outside of HH or AH divisions – use this form.',
    hhMobileAppDescription: 'HH mobile apps – create request via ',
    ahMobileAppDescription: 'AH mobile apps – create request via ',
    cotsMobileAppDescription: 'COTS mobile apps or web clips – create request via ',
    webclipsCotsLinkText: 'WebClips/COTS apps',
    dmsServiceDeskLinkText: 'DMS Service Desk',
    mobileAppsServiceDeskLinkText: 'Mobile Apps Service Desk',
    appInformation: 'App Information',
    compliance: 'Compliance',
    testing: 'Testing',
    packaging: 'Packaging',
    internal: 'Internal',
    public: 'Public',
    previousVersionInfo: (version: string) => `The current version is ${version}. The new version must be higher than this.`,
    divisionWarning: 'Please coordinate with the division contact for specific requirements and approvals before the release.',
    riskAssessmentInfo: 'For Digital SDLC releases, use Digital SDLC release ID',
    demoTitle: (storeName: string) => `Demo Mode for ${storeName} Review`,
    demoInstructionsSummary: (storeName: string) => `Please provide clear instructions on how to activate the demo mode in your app. This is crucial for the ${storeName} review team to effectively test your app.`,
    demoInstructionsTitle: 'Instructions for Demo Mode Setup:',
    demoCredentialsTitle: 'Test Account Credentials (if applicable):',
    demoCredentialsSummary: 'If your app includes a login feature for accessing demo content, please provide test account credentials.',
    demoCredentialsWarning1: 'These are solely for testing purposes and ',
    demoCredentialsWarning2: 'should not contain real user information.',
    forMoreInfo: ' For more information, ',
    clickHere: 'click here.',
    testersTitle: 'Testers:',
    testersMaxCount: (count: number) => `Enter email addresses for a maximum of ${count} testers.`,
    testersAccountLink: (storeName: string) => `Email addresses must be linked to ${storeName} accounts.`,
    uploadedBinary: 'Uploaded Binary:',
    binaryUpdateNote: 'Note: To upload a different binary, the current submission must be cancelled and a new one created.',
    label: {
      name: 'Name',
      version: 'Version',
      appAvailability: 'App Availability',
      platform: 'Platform',
      appIdentifier: 'App Identifier',
      contactEmail: 'Contact Email',
      description: 'Description',
      division: 'Division',
      ciShortName: 'CI Short Name',
      riskAssessment: 'Risk Assessment',
      privacyAssessment: 'Privacy Assessment',
      appReleaseDate: 'App release date',
      username: 'Username',
      password: 'Password',
    },
    validation: {
      appNameRequired: 'App name is required',
      appVersionRequired: 'App version is required',
      invalidAppVersion: 'Enter a valid semantic version (e.g., 1.0.0)',
      versionNotHigher: 'New version must be higher than the previous one',
      appIdRequired: 'App identifier is required',
      invalidIosAppId: 'App Identifier must contain only alphanumeric characters, hyphens (-), and periods (.)',
      invalidAndroidAppId: 'App Identifier must start with a letter and contain only alphanumeric characters or an underscore (_)',
      emailRequired: 'Email address is required',
      invalidCompanyEmail: 'Enter a valid merck or msd email address',
      appDescRequired: 'App description is required',
      appAvailabilityRequired: 'Please choose app availability: Internal or Public',
      platformRequired: 'Please choose a platform',
      divisionRequired: 'Please choose a division',
      ciNameRequired: 'CI short name is required',
      riskAssessmentRequired: 'Risk Assessment is required',
      privacyAssessmentRequired: 'Privacy Assessment is required',
      demoInstructionsRequired: 'Demo mode instructions are required',
      testerEmailsRequired: 'Email addresses of testers are required',
      invalidEmails: 'Enter valid email addresses',
      packagingTypeRequired: 'Please select an option',
      sourceCodeUrlRequired: 'Source code URL is required',
      missingFieldForUpload: 'Please fill in all fields before uploading',
      binaryMissing: 'Please upload the binary',
      invalidUrl: 'Enter a valid url',
      releaseDateRequired: 'App release date is required',
    },
    placeholder: {
      appVersion: 'e.g., 1.0.0',
      testerEmails: 'Enter email addresses separated by comma (,)',
      sourceCodeUrl: 'Enter the URL of the source code',
    },
    result: {
      submissionSuccessTitle: 'Release request submitted',
      updateSuccessTitle: 'Release request updated',
      errorTitle: 'Error',
      submissionErrorMessage: 'There was an issue with your app release submission. Please retry the submission or contact our team for assistance.',
      fetchDetailErrorMessage: 'There was an issue while fetching app details. Please retry or contact our team for assistance.',
      updateSuccessMessage: 'Your release submission has been updated successfully.',
      updateErrorMessage: 'There was an issue while updating your release submission. Please retry the submission or contact our team for assistance.',
      viewJiraTicket: 'View JIRA ticket',
      goHome: 'Go Home',
      retry: 'Retry',
      contactUs: 'Contact Us',
      appReleased: 'App status changed to released',
    },
  },
}
