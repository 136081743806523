import {objectDecoder, oneOfDecoders, undefinedDecoder} from 'json-decoder'
import {MobileAppStatusName, mobileAppStatusNameDecoder} from './MobileAppStatusName'
import {MobileAppBuildInfo, mobileAppBuildInfoDecoder} from './MobileAppBuildInfo'

export type MobileAppStatus = {
  name: MobileAppStatusName
  buildInfo?: MobileAppBuildInfo
}

export const mobileAppStatusDecoder = objectDecoder<MobileAppStatus>({
  name: mobileAppStatusNameDecoder,
  buildInfo: oneOfDecoders(mobileAppBuildInfoDecoder, undefinedDecoder),
})
