import React, {useCallback, useState} from 'react'
import {Button, Input, Form, Space, Spin, Divider, Card, Row} from 'antd'
import {strings} from '../../localization/strings'
import {MobileAppLinks, getAppLinksTitle} from '../../types/MobileAppLinks'
import {Platform} from '../../types/Platform'
import {objectEntries} from '../../helpers/ObjectHelper'

export type AppLinksEditorProps = {
  links: Partial<MobileAppLinks>
  onSave: (updatedLinks: Partial<MobileAppLinks>) => Promise<void>
  onCancel: () => void
  platform: Platform
  isLoading: boolean
}

const AppLinksEditor: React.FC<AppLinksEditorProps> = ({
  links: initialLinks,
  onSave,
  onCancel,
  platform,
  isLoading,
}) => {
  const [links, setLinks] = useState<Partial<MobileAppLinks>>(initialLinks)
  const {buttonTitle} = strings

  const updateLink = useCallback((type: keyof MobileAppLinks, url: string) => {
    setLinks((prevLinks) => ({
      ...prevLinks,
      [type]: url,
    }))
  }, [])

  const handleSave = useCallback(() => {
    onSave(links)
  }, [links, onSave])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Spin spinning={isLoading}>
      <Card style={{padding: 20}}>
        <Form
          layout="horizontal"
          labelCol={{span: 6}}
          wrapperCol={{span: 40}}
          style={{maxWidth: '900px'}}
          initialValues={links}
          onFinish={handleSave}
        >
          {objectEntries(links).map(([key]) => (
            <Form.Item
              key={key}
              name={key}
              label={getAppLinksTitle(key, platform)}
              rules={[{
                type: 'url', message: strings.submission.validation.invalidUrl,
              }]}
            >
              <Input
                onChange={(e) => updateLink(key, e.target.value)}
              />
            </Form.Item>
          ))}

          <Divider />
          <Row justify="start">
            <Space>
              <Button type="primary" htmlType="submit">
                {buttonTitle.saveLinks}
              </Button>
              <Button onClick={handleCancel}>{buttonTitle.cancel}</Button>
            </Space>
          </Row>
        </Form>
      </Card>
    </Spin>
  )
}

export default AppLinksEditor
