import type {ResultStatusType} from 'antd/es/result'
import {SubmissionResponse} from './SubmissionResponse'

export type SubmissionResult = {
    type: SubmissionResultType
    status: ResultStatusType,
    response?: SubmissionResponse,
}

export enum SubmissionResultType {
    FetchAppDetail,
    FormSubmission,
    UpdateSubmission,
}
